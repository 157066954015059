/*NOTE: WORK IN PROGRESS*/
.ps-sidebar-container {
	display: flex;
	border-top: 1px solid var(--border-1);
	min-height: calc(100vh - 54px);
}

.ps-sidebar {
	background-color: var(--bg-c1);
	white-space: nowrap;
}

.ps-sidebar-header {
	border-bottom: 2px solid var(--border-1);
	padding-top: 1em;
	background-color: var(--bg-c3);
}

.ps-sidebar-header li {
	font-weight: bold !important;
	padding-top: 0.2em !important;
	padding-bottom: 0.2em !important;
	border-left: 4px solid var(--bg-c5) !important;
	background-color: var(--bg-c4) !important;
	padding-right: 0.5em !important;
}

.ps-sidebar-left {
	border-right: 1px solid var(--border-1);
}

.ps-sidebar-right {
	border-left: 1px solid var(--border-1);
}

.ps-sidebar-content {
	flex: 1;
	padding: 5px;
	background-color: var(--bg-c1);
}

.ps-sidebar ul {
	padding: 0px;
	margin: 0px;
	display: flex;
	flex-direction: column;
}

.ps-sidebar ul li {
	margin: 0px;
	padding: 0.625em;
	background-color: var(--bg-c1);
	color: var(--text-c1);
	cursor: pointer;
	list-style: none;
}

.ps-sidebar-left ul li {
	border-left: 4px solid #c6c6c6;
	padding-right: 10px;
}

.ps-sidebar ul li.active {
	margin: 0px;
	background-color: var(--bg-c3);
	padding: 0.625em;
}

.ps-sidebar-left ul li.active {
	border-left: 4px solid #2070D1;
	padding-right: 10px;
}

.ps-sidebar-right ul li.active {
	border-right: 4px solid #2070D1;
}

.ps-sidebar ul li:hover {
	background-color: var(--bg-c2);
}

.ps-sidebar-title {
	font-weight: normal;
	margin-right: 1em;
}

.ps-sidebar-btn {
	position: fixed;
	bottom: 1em;
	border-radius: 50%;
}

.ps-sidebar-btn:hover {
	scale: 1.05;
}
