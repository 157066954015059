/*NOTE: WORK IN PROGRESS*/
.ps-btn-primary[disabled], .ps-btn-primary[disabled]:hover,
.ps-btn-success[disabled], .ps-btn-success[disabled]:hover,
.ps-btn-default[disabled], .ps-btn-default[disabled]:hover,
.ps-btn-warning[disabled], .ps-btn-warning[disabled]:hover,
.ps-btn-danger[disabled], .ps-btn-danger[disabled]:hover{
	filter:grayscale(0.6);
	cursor: not-allowed;
}

.ps-btn-group, .ps-btn-group-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle;
}

.ps-grow {
	transition: all .2s ease-in-out;
	cursor: pointer;
}

.ps-grow:hover {
	transform: scale(1.1);
}

.ps-btn-group button {
	border-radius: 0;
	float: left;
}

.ps-btn-group-vertical button {
	border-radius: 0;
	display:block;
	margin-top: -1px;
}

.ps-btn-group button:first-child {
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.ps-btn-group button:last-child {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.ps-btn-group-vertical button:first-child {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.ps-btn-group-vertical button:last-child {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.ps-btn-primary {
	background-color: var(--bg-primary);
	border: none;
	border-radius: 0.5em;
	padding: 0.4em 0.7em;
	color: var(--text-c1);
	font-size: 1em;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.ps-btn-success {
	background-color: var(--bg-success);
	border: none;
	border-radius: 0.5em;
	padding: 0.4em 0.7em;
	color: #fff;
	font-size: 1em;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.ps-btn-warning {
	background-color: var(--bg-warning);
	border: none;
	border-radius: 0.5em;
	padding: 0.4em 0.7em;
	color: #fff;
	font-size: 1em;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.ps-btn-default {
	background-color: var(--bg-default);
	border: 1px solid #bebebe;
	border-radius: 0.5em;
	padding: 0.35em 0.7em;
	color: var(--text-c1);
	font-size: 1em;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.ps-btn-danger {
	background-color: var(--bg-danger);
	border: none;
	border-radius: 0.5em;
	padding: 0.4em 0.7em;
	color: #fff;
	font-size: 1em;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
}


.ps-btn:hover,
.ps-btn-danger:hover,
.ps-btn-warning:hover,
.ps-btn-default:hover,
.ps-btn-success:hover,
.ps-btn-primary:hover,
.ps-btn-search:hover {
	filter: brightness(0.9);
}

.ps-btn-danger.ps-btn-sm,
.ps-btn-primary.ps-btn-sm,
.ps-btn-success.ps-btn-sm,
.ps-btn-warning.ps-btn-sm
{
	font-size: 0.85em;
	padding: 0.3em 0.5em;
}

.ps-btn.ps-btn-sm,
.ps-btn-default.ps-btn-sm
{
	font-size: 0.85em;
	padding: 0.2em 0.5em;
}

.ps-btn-danger.ps-btn-xs,
.ps-btn-primary.ps-btn-xs,
.ps-btn-success.ps-btn-xs,
.ps-btn-warning.ps-btn-xs
{
	font-size: 0.8em;
	padding: 0.2em 0.45em;
}

.ps-btn.ps-btn-xs,
.ps-btn-default.ps-btn-xs
{
	font-size: 0.8em;
	padding: 0.1em 0.45em;
}

button.ps-btn-danger.ps-btn-md,
button.ps-btn-primary.ps-btn-md,
button.ps-btn-success.ps-btn-md,
button.ps-btn-warning.ps-btn-md
{
	font-size: 1em;
	padding: 0.5em 1em;
}

.ps-btn.ps-btn-md,
.ps-btn-default.ps-btn-md
{
	font-size: 1em;
	padding: 0.4em 1em;
}

button.ps-btn-danger.ps-btn-lg,
button.ps-btn-primary.ps-btn-lg,
button.ps-btn-success.ps-btn-lg,
button.ps-btn-warning.ps-btn-lg
{
	font-size: 1.1em;
	padding: 0.71em 1.16em;
}

.ps-btn.ps-btn-lg,
.ps-btn-default.ps-btn-lg
{
	font-size: 1.1em;
	padding: 0.61em 1.16em;
}