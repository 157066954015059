/* The following is made to be backward compatible with bs3 */
/* to accommodate the conversion from bs3 to ui-ps styling */
/* replacement for some of bs3 input related styling */
.ps-form-control {
	width: 100%;
	height: 2.25em;
	padding: 0px 8px 0px 8px;
	border: 1px solid var(--border-4);
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	background-color: var(--bg-c2);
	color: var(--text-c1);
}

.ps-form-control::placeholder {
	color: var(--text-c1);
	opacity: 0.7;
}

.ps-form-control:focus {
	border: none !important;
	box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
	outline: 0.15em solid var(--fg-primary);
}

/*Prevents yellow input background when browser uses autofill*/
.ps-form-control:-webkit-autofill,
.ps-form-control:-webkit-autofill:hover,
.ps-form-control:-webkit-autofill:focus,
.ps-form-control:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 50px var(--bg-c1) inset;
	box-shadow: 0 0 0 50px var(--bg-c1) inset;
	-webkit-text-fill-color: var(--fg-c1);
}

/*Sets ps-form-control textarea vertical alignment in Chrome to the same as Firefox*/
.ps-input-group > textarea.ps-form-control {
	vertical-align: middle;
}

textarea.ps-form-control {
	height: auto;
}

.ps-input-group {
	position: relative;
	display: table;
	border-collapse: separate;
}

.ps-input-group-btn {
	position: relative;
	white-space: nowrap;
}

/* For buttons on the right of the input */
.ps-input-group-btn.right > button,
.ps-input-group-btn.right > .ps-btn-group > button {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-left: none;
}

/* For buttons on the left of the input */
.ps-input-group-btn.left > button,
.ps-input-group-btn.left > .ps-btn-group > button {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	border-right: none;
}

.ps-form-control + .ps-input-group-btn > button {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-left: none;
}

.ps-input-group-addon {
	height: 2.25em;
	padding: 0px 8px 0px 8px;
	background-color: var(--bg-c2);
	border: 1px solid var(--border-4);
	border-radius: 4px;
	color: var(--text-c1);
}

.ps-input-group-addon:first-child {
	border-right: 0;
}

.ps-input-group .ps-form-control:first-child,
.ps-input-group .ps-input:first-child,
.ps-input-group-addon:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.ps-input-group .ps-form-control:last-child,
.ps-input-group .ps-input:last-child,
.ps-input-group .ps-input-group-btn:last-child,
.ps-input-group-addon:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.ps-input-group-addon,
.ps-input-group-btn {
	width: 1%;
	white-space: nowrap;
	vertical-align: middle;
}

.ps-input-group .ps-form-control,
.ps-input-group .ps-input,
.ps-input-group-addon,
.ps-input-group-btn {
	display: table-cell;
}

.ps-input-group .ps-form-control:not(:first-child):not(:last-child),
.ps-input-group .ps-input:not(:first-child):not(:last-child),
.ps-input-group .ps-input-group-btn:not(:first-child):not(:last-child),
.ps-input-group-addon:not(:first-child):not(:last-child) {
	border-radius: 0;
}


.ps-form-inline .ps-form-group {
	display: inline-block;
	margin-bottom: 0;
	vertical-align: middle;
}

.ps-form-inline .ps-input-group {
	display: inline-table;
	vertical-align: middle;
}

.ps-form-inline .ps-input-group .ps-form-control,
.ps-form-inline .ps-input-group .ps-input-group-addon,
.ps-form-inline .ps-input-group .ps-input-group-btn {
	width: auto;
}


.ps-form-inline .ps-input-group > .ps-form-control {
	width: 100%;
}
