/* small ps-switch styling */
.ps-switch-sm {
	position: relative;
	display: inline-block;
	width: 1.5em;
	height: 0.85em;
	margin-bottom: 0px;
	caret-color: transparent;
}

.ps-switch-sm input {
	display: none;
}

.ps-slider-sm {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--bg-c3);
	transition: 0.4s;
	border-radius: 0.85em;
}

.ps-slider-sm:before {
	position: absolute;
	content: "";
	height: 0.65em;
	width: 0.65em;
	left: 0.1em;
	bottom: 0.1em;
	background-color: var(--bg-c1);
	transition: 0.4s;
	border-radius: 50%;
}

input:checked + .ps-slider-sm {
	background-color: var(--fg-success);
}

input:checked + .ps-slider-sm:before {
	transform: translateX(0.65em);
}

/* regular (between regular and small) ps-switch styling */
.ps-switch{
	position: relative;
	display: inline-block;
	width: 2.3em;
	height: 1.3em;
	margin-bottom: 0px;
	caret-color: transparent;
}

.ps-switch input {
	display: none;
}

.ps-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--bg-c3);
	transition: 0.4s;
	border-radius: 0.85em;
}

.ps-slider:before {
	position: absolute;
	content: "";
	height: 1.1em;
	width: 1.1em;
	left: 0.1em;
	bottom: 0.08em;
	background-color: var(--bg-c1);
	transition: 0.4s;
	border-radius: 50%;
}

input:checked + .ps-slider{
	background-color: var(--fg-success);
}

input:checked + .ps-slider:before {
	transform: translateX(0.96em);
}

/* large ps-switch styling */
.ps-switch-lg {
	position: relative;
	display: inline-block;
	width: 3em;
	height: 1.7em;
	margin-bottom: 0px;
	caret-color: transparent;
}

.ps-switch-lg input {
	display: none;
}

.ps-slider-lg {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--bg-c3);
	transition: 0.4s;
	border-radius: 1.7em;
}

.ps-slider-lg:before {
	position: absolute;
	content: "";
	height: 1.3em;
	width: 1.3em;
	left: 0.2em;
	bottom: 0.2em;
	background-color: var(--bg-c1);
	transition: 0.4s;
	border-radius: 50%;
}

input:checked + .ps-slider-lg {
	background-color: var(--fg-success);
}

input:checked + .ps-slider-lg:before {
	transform: translateX(1.3em);
}