.ps-tag-container {
	display: block;
}

.ps-tag-container>div {
	display: inline-flex;
	flex-flow: row wrap;
	width: 100%;
	height: auto;
	min-height: 2.25em;
	align-content: center;
}

.ps-tag-container>ul {
	width: 96%;
}

.ps-tag-container>div>label {
	margin: 0.5em 0 0.5em 0.2em;
	align-content: center;
	padding: 0.4em;
}

.ps-tag-container>div>label>div {
	display: inline-block;
	min-width: 4em;
}

.ps-tag-container>div>label>i {
	display: inline-block;
	cursor: pointer;
}

.ps-tag-container>div>input {
	color: var(--fg-c1);
	background-color: transparent;
	height: 2.25em;
	font-size: 1em;
	border: 0;
	outline: 0;
	flex: 1;
}
