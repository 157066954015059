/* for horisontal tabbing */
.ps-tabs {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	border-bottom: 1px solid var(--border-4);
}

.ps-tabs > li {
	margin-bottom: -1px;
}

.ps-tabs > li > a {
	display: block;
	padding: 8px 15px;
	border-radius: 5px 5px 0 0;
	text-decoration: none;
	color: var(--text-c1);
}

.ps-tabs > li > a:hover {
	background-color: var(--bg-c3);
	border-color: transparent;
}

.ps-tabs > li.active > a,
.ps-tabs > li.active > a:focus, 
.ps-tabs > li.active > a:hover {
	background-color: transparent;
	border: 1px solid var(--border-4);
}
