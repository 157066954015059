.ps-bg-aliceblue {
	background-color: aliceblue;
}

.ps-bg-faintgrey {
	background-color: rgba(240, 240, 240, 0.5);
}

/* Pale Green with Opacity */
.ps-bg-pale-green {
	background-color: rgba(152, 251, 152, 0.2); /* ps-palegreen with opacity */
}

/* Coral with Opacity */
.ps-bg-pale-coral {
	background-color: rgba(255, 127, 80, 0.2); /* coral with opacity */
}

/* Baby Blue with Opacity */
.ps-bg-pale-babyblue {
	background-color: rgba(173, 216, 230, 0.2); /* babyblue with opacity */
}

/* Sea Green with Opacity */
.ps-bg-pale-seagreen {
	background-color: rgba(46, 139, 87, 0.2); /* seagreen with opacity */
}

/* Salmon with Opacity */
.ps-bg-pale-salmon {
	background-color: rgba(250, 128, 114, 0.2); /* salmon with opacity */
}

.ps-bg-primary {
	background-color: var(--bg-primary);
	color: var(--text-primary);
}

.ps-bg-success {
	background-color: var(--bg-success);
	color: var(--text-primary);
}

.ps-bg-danger {
	background-color: var(--bg-danger);
	color: var(--text-primary);
}

.ps-bg-warning {
	background-color: var(--bg-warning);
	color: var(--text-primary);
}

.ps-bg-default {
	background-color: var(--bg-default);
	color: var(--text-primary);
}
