
:root {
	font-family: var(--font-family);
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;

	margin: 0;
	padding: 0;

	background-color: transparent;

	box-sizing: border-box;
}

::after, ::before {
	box-sizing: border-box;
}

* {
	font-family: inherit;
	box-sizing: border-box;
	margin: 0;
}

body {
	background-color: var(--bg-c1);
	color: var(--text-c1);
}


hr {
	margin-top: 1em;
	margin-bottom: 1em;
	border: 0;
	border-top: 0.1em solid var(--fg-c1);
}

/* prevent default margin bottom 5px */
label {
	margin-bottom: 0;
	font-weight: 700;
}

.ps-hide-scrollbar::-webkit-scrollbar {
	display: none;
}

::-webkit-scrollbar {
	width: 0.4em;
}

::-webkit-scrollbar:hover {
	width: 0.9em;
}

::-webkit-scrollbar-thumb {
	background-color: var(--text-c4);
	border-radius: 0.5em;
}

::-webkit-scrollbar-thumb:hover {
	background-color: var(--text-c2);
}

::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 0.5em;
}

.h1, .h2, .h3, h1, h2, h3 {
	display: block;
	margin-top: 1.2em;
	margin-bottom: 0.6em;
	font-weight: 350;
}

.h1, h1 {
	font-size: 2.0em;
}
.h2, h2 {
	font-size: 1.5em;
}
.h3, h3 {
	font-size: 1.17em;
}
.h4, h4 {
	font-size: 1.0em;
}
.h5, h5 {
	font-size: 0.83em;
}
