.ps-loader-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0 0 0 / 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.ps-loader-container {
	background-color: transparent;
	text-align: center;
}

.ps-loader-icon {
	font-size: 7em;
	color: var(--bg-primary);
}

.ps-loader-message {
	margin-top: 1em;
	font-size: 1.5em;
	color: #ffffff;
}