.ps-text-warning {
	color: var(--fg-warning);
}

.ps-text-danger {
	color: var(--fg-danger);
}

.ps-text-primary {
	color: var(--fg-primary);
}

.ps-text-success {
	color: var(--fg-success);
}
