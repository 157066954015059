/* Fieldset classes*/
fieldset.ps-fieldset {
	border: 0.1em solid var(--border-4);
	border-radius: 0.4em;
	padding: 0.9em;
	margin: 1em 1em 1em 1em;
	min-width: 15%;
}

fieldset.ps-fieldset legend {
	font-size: 1.1em;
	padding-inline: 0.5em;
	position: relative;
	z-index: 1;
	top: -0.15em;
	width: auto;
	border: 0;
	color: var(--text-c1);
	margin-bottom: 0; /* Cancel out bs3 setting */
}

.ps-fieldset-container {
	border: 0.1em solid var(--border-4);
	border-radius: 0.4em;
	padding: 0.9em;
	margin: 2em 1em 1em 1em;
	min-width: 15%;
}

.ps-fieldset-title {
	font-size: 1.1em;
	padding-inline: 0.5em;
	position: relative;
	z-index: 1;
	top: -0.15em;
	width: auto;
	border: 0;
	color: var(--text-c1);
}

.ps-fieldset-row {
	display: flex;
	align-items: baseline;
}

.ps-fieldset-row-data {
	margin-left: 10px;
}

.ps-fieldset-row-title {
	font-weight: bold;
	min-width: 10em;
}
