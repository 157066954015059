/* Hides navbar scrollbar to avoid closing of nav-dropdown content when moving over the scrollbar */
.ps-navbar-container::-webkit-scrollbar {
	display: none; /* Hides the scrollbar for WebKit browsers */
}

.ps-navbar-container {
	scrollbar-width: none; /* Hides the scrollbar in Firefox */
	margin-top: var(--navbar-height);
}

.ps-navbar {
	position: fixed;
	z-index: 998;
	width: 100%;
	background-color: var(--bg-c2);
	top: 0;
	height: var(--navbar-height);
}

.ps-navbar .ps-link-container {
	position: relative;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ps-link-container {
	display: inline-flex;
	width: 100%;
	align-items: center;
	height: var(--navbar-height);
}

.ps-link-container .ps-logo a {
	font-weight: 600;
	color: var(--text-c1);
	text-decoration: none;
}

.ps-logo {
	margin: 0 0 0 0.5em;
}

.ps-link-container .ps-nav-links {
	display: inline-flex;
	width: 100%;
	height: 100%;
	margin-bottom: 0;
	margin-top: 0;
}

.ps-nav-links-right {
	display: flex;
	margin-left: auto;
}

.ps-nav-links li {
	list-style: none;
	align-items: center;
	display: flex;
	height: 100%;
	cursor: pointer;
}

.ps-nav-links li a {
	color: var(--fg-c1);
	text-decoration: none;
	font-weight: 500;
	padding: 9px 15px;
	border-radius: 5px;
	white-space: nowrap;
}

.ps-nav-links li a:hover,
.ps-nav-links li label:hover {
	background-color: var(--bg-c3);
	border-radius: 4px;
}

.ps-nav-links .ps-nav-dropdown {
	position: absolute;
	line-height: 45px;
	top: var(--navbar-height);
	opacity: 0;
	visibility: hidden;
	box-shadow: 0 6px 10px rgba(0,0,0,0.15);
	z-index: 100;
	padding-left: 0px;
	background-color: var(--bg-c2);
	border-radius: 0 0 4px 4px;
	white-space: nowrap;
	min-width: 8em;
	max-height: 51em;
	overflow-y: scroll;
}

.ps-nav-links li:hover .ps-nav-dropdown {
	top: var(--navbar-height);
}

.ps-nav-links li.open .ps-nav-dropdown {
	opacity: 1;
	visibility: visible;
}

.ps-dropdown-hide {
	visibility: hidden !important;
}

.ps-nav-dropdown li a {
	width: 100%;
	display: block;
	font-weight: 400;
	border-radius: 0px;
	padding: 0.2em 0.4em;
}

.ps-nav-dropdown li:last-child {
	border-radius: 0 0 4px 4px;
}

.ps-nav-links li ul:hover li:last-child {
	border-radius: 0 0 4px 4px;
}

.ps-link-container .ps-close-btn,
.ps-link-container .ps-menu-btn {
	color: var(--text-c1);
	font-size: 20px;
	cursor: pointer;
	display: none;
}

.ps-nav-heading {
	font-size: 1.2em;
}

.ps-nav-subheading {
	font-size: 0.8em;
}

.ps-menu-btn {
	margin-right: 1em;
	margin-bottom: 0;
}

nav input {
	display: none;
}

.mobile-item {
	display: none;
}

.ps-link-container .ps-close-btn,
.ps-link-container .ps-menu-btn{
	color: var(--fg-c1);
	font-size: 20px;
	cursor: pointer;
	display: none;
}

@media screen and (max-width: 970px) {
	.ps-link-container .ps-close-btn{
		display: flex;
		justify-content: flex-end;
	}
	.ps-link-container .ps-menu-btn {
		display: block;
	}

	.ps-link-container .ps-nav-links{
		position: fixed;
		height: 100vh;
		width: 100%;
		max-width: 350px;
		top: 0;
		left: -100%;
		background: var(--bg-c2);
		display: block;
		padding: 50px 10px;
		line-height: 50px;
		overflow-y: auto;
		box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
		margin-bottom: 0;
	}

	#ps-menu-btn:checked ~ .ps-nav-links {
		left: 0%;
	}

	#ps-menu-btn:checked ~ .ps-menu-btn {
		display: none;
	}

	#ps-close-btn:checked ~ .ps-close-btn {
		display: block;
	}

	.ps-nav-links li {
		margin: 15px 10px;
		height: auto;
		display: block;
	}

	.ps-nav-links li a {
		padding: 0 20px;
		display: block;
		font-size: 20px;
	}

	.ps-nav-links-right {
		display: block;
	}

	.ps-nav-links .ps-nav-dropdown {
		position: static;
		opacity: 1;
		top: 65px;
		visibility: visible;
		padding-left: 20px;
		width: 100%;
		max-height: 0px;
		overflow: hidden;
		box-shadow: none;
	}

	[id*="psShow"]:checked ~ .ps-nav-dropdown { /* selects any ID starting with psShow to display dropdown in mobile view*/
		max-height: 100%;
	}

	.ps-nav-links .desktop-item{
		display: none;
	}

	.ps-nav-links .mobile-item{
		display: block;
		color: var(--text-c1);
		font-size: 20px;
		font-weight: 500;
		padding-left: 20px;
		cursor: pointer;
		border-radius: 5px;
	}

	.ps-dropdown li{
		margin: 0;
	}

	.ps-dropdown li a{
		border-radius: 5px;
		font-size: 18px;
	}
  
	.content .row header{
		font-size: 19px;
	}
}