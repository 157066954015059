.ps-tree,
.ps-tree ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ps-tree ul {
	margin-left: 1em;
	position: relative;
}

.ps-tree ul:before {
	content: "";
	display: block;
	width: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	border-left: 2px solid;
	height: calc(100% - 1em);
}

.ps-tree li {
	margin: 0;
	padding: 0 5em;
	line-height: 2em;
	color: var(--fg-c1);
	font-weight: 700;
	position: relative;
}

.ps-tree ul li:before {
	content: "";
	display: block;
	width: 4em;
	height: 0;
	border-top: 2px solid;
	position: absolute;
	top: 1em;
	left: 0;
}

.ps-tree ul li:last-child:before {
	background: transparent;
	height: auto;
	top: 1em;
	bottom: 0;
}

.ps-tree a {
	text-decoration: none;
	color: var(--text-c1);
}