/*NOTE: WORK IN PROGRESS*/

/*ps-modal-container used for dialogs too*/
.ps-modal-container {
	display: none;
	position: fixed;
	z-index: 1;
	background-color: rgba(0,0,0,0.4);
	margin: auto;
	overflow: auto;
	top: 0;
	left: 0;
	padding: 0;
	border: 1px solid #888;
	width: 100%;
	height: 100%;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
	animation-name: animatetop;
	animation-duration: 0.4s;
}

.ps-modal {
	background-color: var(--bg-c1);
	width: 50%;
	margin: 15% auto;
	border: 1px solid #888;
}

.ps-modal-header {
	padding: 2px 16px;
	background-color: var(--fg-c1);
	color: var(--bg-c1);
}

.ps-modal-body {
	padding: 2px 16px;
	background-color: var(--bg-c1);
	color: var(--fg-c1);
}

.ps-modal-footer {
	padding: 2px 16px;
	background-color: var(--fg-c1);
	color: var(--bg-c1);
}

@keyframes animatetop {
	from {top: -300px; opacity: 0}
	to {top: 0; opacity: 1}
} 
