.ps-label {
	color: var(--fg-c1);
	background-color: transparent;
	display: inline;
	padding: .2em .6em .3em;
	font-size: 75%;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25em;
}

.ps-label-primary {
	background-color: var(--fg-primary);
}

.ps-label-success {
	background-color: var(--fg-success);
}

.ps-label-danger {
	background-color: var(--fg-danger);
}

.ps-label-warning {
	background-color: var(--fg-warning);
}

.ps-label-default {
	background-color: var(--fg-default);
}

/* replace BS3 class */
.ps-sr-only {
	border: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.ps-sr-only-focusable:active, .sr-only-focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}