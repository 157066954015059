/*NOTE: WORK IN PROGRESS*/
:root.theme-default-dark {
	--fg-c1: #d9dbe0;
	--bg-c1: #262626;
	--text-c1: #dcdcdc;

	--fg-c2: #7d56a1;
	--bg-c2: #38393c;
	--text-c2: #b3b8bd;

	--fg-c3: #3c7fd5;
	--bg-c3: #151515;
	--text-c3: #5a6d8c;

	--fg-c4: #151515;
	--bg-c4: #303030;
	--text-c4: #eeeeee;

	--fg-c5: #b0b0b0;
	--bg-c5: #c9c9c9;
	--text-c5: black;

	--fg-c6: #282828;
	--bg-c6: #181818;

	--border-1: #393a3d;
	--border-2: #7a7f85;
	--border-3: #bebebe;
	--border-4: #dee2e6;

	--bg-shadow: rgb(0 0 0 / 95%);

	--fg-warning:#ac8824;
	--fg-danger: #9e3733;
	--fg-success: #478e56;
	--fg-default: #fff;
	--fg-primary: #3b6dd9;

	--bg-warning:#ac8824;
	--bg-danger: #9e3733;
	--bg-success: #478e56;
	--bg-default: #38393c;
	--bg-primary: #3b6dd9;

	--fa-primary-color: #C3C8D3;
	--fa-secondary-color: #cacaca;

	--font-family: "Lato", sans-serif; 
}
