.ps-progress-container {
	width: 100%;
	background-color: var(--bg-c3);
	border-radius: 25px;
	overflow: hidden;
	margin: 2em 0;
}

.ps-progressbar-group {
	display: flex;
}

.ps-progressbar-group div:last-child {
	border-radius: 0px;
}

.ps-progressbar-group div:first-child {
	border-radius: 25px 0 0 25px;
}

[class^='ps-progressbar-'], [class*=' ps-progressbar-'] {
	height: 2em;
	width: 60%; /* Change this to set the progress */
	/*border-radius: 25px 0 0 25px;*/
}

.ps-progressbar-success {
	background-color: var(--bg-success);
}

.ps-progressbar-primary {
	background-color: var(--bg-primary);
}

.ps-progressbar-warning {
	background-color: var(--bg-warning);
}

.ps-progressbar-danger {
	background-color: var(--bg-danger);
}

[class^='ps-progress-'], [class*=' ps-progress-'] {
	width: 100%;
	height: 25px;
	appearance: none;
	-webkit-appearance: none;
	border-radius: 15px;
	overflow: hidden;
	background-color: var(--bg-c3);
	border-color: var(--bg-c3);
}

/* 
	Success 
*/
/* Webkit-based browsers */
.ps-progress-success::-webkit-progress-bar {
	background-color: var(--bg-c3);
	border-radius: 15px;
}

.ps-progress-success::-webkit-progress-value {
	background-color: var(--bg-success);
	border-radius: 15px 0 0 15px;
	transition: width 0.4s ease-in-out;
}

/* Firefox */
.ps-progress-success::-moz-progress-bar {
	background-color: var(--bg-success);
	border-radius: 15px;
	transition: width 0.4s ease-in-out;
}

/* 
	Primary 
*/
/* Webkit-based browsers */
.ps-progress-primary::-webkit-progress-bar {
	background-color: var(--bg-c3);
	border-radius: 15px;
}

.ps-progress-primary::-webkit-progress-value {
	background-color: var(--bg-primary);
	border-radius: 15px 0 0 15px;
	transition: width 0.4s ease-in-out;
}

/* Firefox */
.ps-progress-primary::-moz-progress-bar {
	background-color: var(--bg-primary);
	border-radius: 15px;
	transition: width 0.4s ease-in-out;
}

/* 
	Warning 
*/
/* Webkit-based browsers */
.ps-progress-warning::-webkit-progress-bar {
	background-color: var(--bg-c3);
	border-radius: 15px;
}

.ps-progress-warning::-webkit-progress-value {
	background-color: var(--bg-warning);
	border-radius: 15px 0 0 15px;
	transition: width 0.4s ease-in-out;
}

/* Firefox */
.ps-progress-warning::-moz-progress-bar {
	background-color: var(--bg-warning);
	border-radius: 15px;
	transition: width 0.4s ease-in-out;
}

/* 
	Danger 
*/
/* Webkit-based browsers */
.ps-progress-danger::-webkit-progress-bar {
	background-color: var(--bg-c3);
	border-radius: 15px;
}

.ps-progress-danger::-webkit-progress-value {
	background-color: var(--bg-danger);
	border-radius: 15px 0 0 15px;
	transition: width 0.4s ease-in-out;
}

/* Firefox */
.ps-progress-danger::-moz-progress-bar {
	background-color: var(--bg-danger);
	border-radius: 15px;
	transition: width 0.4s ease-in-out;
}