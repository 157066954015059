/*NOTE: WORK IN PROGRESS*/
.ps {/*added by ps provider*/
	dialog[open] {
		border: none;
		opacity: 1;
		padding: 0;
		border-radius: 5px;
		max-height: 80vh;
		max-width: 80vw;
		overflow: visible;
	}

	dialog {
		border: none;
		padding: 0;
		border-radius: 5px;
		max-height: 80vh;
		max-width: 80vw;
		overflow: visible;
		opacity: 0;
	}

	dialog::backdrop {
		background-color: rgb(0 0 0 / 0);
	}

	dialog[open]::backdrop {
		background-color: rgb(0 0 0 / 0.5);
		backdrop-filter: blur(1px);
	}

	.ps-dialog {
		display: flex;
		flex-direction: column;
		max-height: 80vh;
		max-width: 80vw;
		color: var(--text-c1);
	}

	.ps-dialog,
	.ps-dialog-xl,
	.ps-dialog-lg,
	.ps-dialog-md,
	.ps-dialog-sm,
	.ps-dialog-xs
	{
		position: fixed;
		left: 50%;
		top: 10%;
		transform: translateX(-50%);
		box-shadow: 0px 0px 1em 1em rgba(0,0,0,0.4);
	}

	/*for backwards compatibility with bs3 structured dialogs*/
	.content {
		max-height: 80vh;
		display: flex;
		flex-direction: column;
	}

	.ps-dialog-header {
		padding: 0.2em;
		background-color: var(--bg-c3);
		color: var(--text-c1);
		text-align: center;
		font-size: 1.75em;
		border-radius: 5px 5px 0px 0px
	}

	.ps-dialog-body {
		height: 100%;
		overflow: auto;
		padding: 1em;
		background-color: var(--bg-c1);
		color: var(--text-c1);
	}

	.ps-dialog-footer {
		padding: 0.2em;
		background-color: var(--bg-c3);
		color: var(--text-c1);
		border-radius: 0px 0px 5px 5px
	}

	div.ps-dialog-footer div.ps-btn-group {
		display: flex;
		justify-content: flex-end;
	}

	.ps-dialog-icon {
		color: var(--fg-c1);
	}

	/*Dialog sizes (DEFAULT == md)*/
	.ps-dialog-xl {
		width: 65em;
	}

	.ps-dialog-lg {
		width: 52em;
	}

	.ps-dialog-sm {
		width: 34em;
	}

	.ps-dialog-xs {
		width: 25em;
	}

	@media screen and (max-width: 48em) {
		.ps-dialog-lg,
		.ps-dialog-md,
		.ps-dialog-sm,
		.ps-dialog-xs {
			width: 90%;
			margin: 0 auto;
		}
	}
}
