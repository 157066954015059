.ps-page {
	background-color: var(--bg-c1);
	color: var(--fg-c1);
}

.ps-container {
	padding-right: 1em;
	padding-left: 1em;
	margin-right: auto;
	margin-left: auto;
}

div.ps-container {
	width: 95%;
}

.ps-page-body {
	padding: 1em;
	background-color: var(--bg-c1);
}

/* double extra large overflow body */
.ps-overflow-body-xxl {
	max-height: 60em;
	overflow-y: scroll;
	background-color: var(--bg-c1);
}

/* extra large overflow body */
.ps-overflow-body-xl {
	max-height: 50em;
	overflow-y: scroll;
	background-color: var(--bg-c1);
}

/* large overflow body */
.ps-overflow-body-lg {
	max-height: 50em;
	overflow-y: scroll;
	background-color: var(--bg-c1);
}

/* default overflow body */
.ps-overflow-body {
	max-height: 40em;
	overflow-y: scroll;
	background-color: var(--bg-c1);
}

/* small overflow body */
.ps-overflow-body-sm {
	max-height: 30em;
	overflow-y: scroll;
	background-color: var(--bg-c1);
}

/* extra small overflow body */
.ps-overflow-body-xs {
	max-height: 20em;
	overflow-y: scroll;
	background-color: var(--bg-c1);
}

/* double extra small overflow body */
.ps-overflow-body-xxs {
	max-height: 10em;
	overflow-y: scroll;
	background-color: var(--bg-c1);
}

/* REPLACES bootstrap 3's class="alert alert-info/danger/success/warning" */
.ps-alert-info {
	color: #31708f;
	background-color: #bce9ff;
	padding: 15px;
	margin-bottom: 20px;
	border-radius: 4px;
}

.ps-alert-danger {
	color: #a94442;
	background-color: #ffbbbb;
	padding: 15px;
	margin-bottom: 20px;
	border-radius: 4px;
}

.ps-alert-success {
	color: #3c763d;
	background-color: #c6e7b9;
	padding: 15px;
	margin-bottom: 20px;
	border-radius: 4px;
}

.ps-alert-warning {
	color: #8a6d3b;
	background-color: #fff2af;
	padding: 15px;
	margin-bottom: 20px;
	border-radius: 4px;
}

.ps-alert-primary {
	color: #4a4a4a;
	background-color: #cbcbcb;
	padding: 15px;
	margin-bottom: 20px;
	border-radius: 4px;
}

.ps-well {
	min-height: 1.5em;
	padding: 1.5em;
	margin-bottom: 1.5em;
	background-color: var(--bg-c1);
	border: 1px solid #e3e3e3;
	border-radius: 5px;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

.ps-well-lg {
	padding: 1.75em;
}

.ps-well-sm {
	padding: 1.75em;
}

.ps-well-xs {
	padding: 1.25em;
}

.ps-pull-right {
	float: right;
}

.ps-pull-left {
	float: left;
}