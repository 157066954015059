/*NOTE: WORK IN PROGRESS*/
.ps-panel {
	color: var(--fg-c1);
	background-color: var(--bg-c1);
	border: 1px solid var(--border-4);
	border-radius: 5px;
}

.ps-panel-footer {
	padding: 20px;
	background-color: var(--bg-c1);
	color: var(--fg-c1);
	border-top: 1px solid var(--border-4);
	border-radius: 0 0 5px 5px;
}

.ps-panel-header {
	padding: 20px;
	background-color: var(--fg-c1);
	color: var(--bg-c1);
	border-bottom: 1px solid var(--border-4);
	border-radius: 5px 5px 0 0;
}

.ps-panel-body {
	padding: 20px;
}

.ps-panel-title {
	font-weight: bold;
}

.ps-panel-actions {
}
