.ps-pagination {
	display: inline-block;
	padding-left: 0;
	margin: 20px 0;
	border-radius: 4px;
}

.ps-pagination > li {
	display: inline;
}

.ps-pagination > li > a,
.ps-pagination > li > span {
	position: relative;
	float: left;
	padding: 6px 12px;
	margin-left: -1px;
	line-height: 1.428571429;
	text-decoration: none;
	background-color: var(--bg-c2);
	border: 1px solid var(--border-2) !important;
	color: var(--text-c1);
}

.ps-pagination > li:first-child > a,
.ps-pagination > li:first-child > span {
	margin-left: 0;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}

.ps-pagination > li:last-child > a,
.ps-pagination > li:last-child > span {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.ps-pagination > li > a:hover,
.ps-pagination > li > span:hover,
.ps-pagination > li > a:focus,
.ps-pagination > li > span:focus {
	background-color: var(--bg-c3);
	cursor: pointer;
}

.ps-pagination > .active > a,
.ps-pagination > .active > span,
.ps-pagination > .active > a:hover,
.ps-pagination > .active > span:hover,
.ps-pagination > .active > a:focus,
.ps-pagination > .active > span:focus {
	color: #fff;
	cursor: default;
	background-color: #43a1f3;
	border-color: #287dc7;
}

.ps-pagination > .disabled > span,
.ps-pagination > .disabled > span:hover,
.ps-pagination > .disabled > span:focus,
.ps-pagination > .disabled > a,
.ps-pagination > .disabled > a:hover,
.ps-pagination > .disabled > a:focus {
	color: var(--text-c1);
	cursor: not-allowed;
	background-color: var(--bg-c2);
	border-color: var(--border-2);
}
