.ps-nav-pill {
	display: flex;
	border-radius: 1.5em 1.5em 0 0;
	overflow: hidden;
	list-style: none;
	padding: 0;
	margin: 0;
	background-color: var(--bg-c4);
	border-bottom: 7px solid var(--text-c1);
}

.ps-nav-pill li {
	flex: 1;
}

.ps-nav-pill li {
	text-align: center;
	padding: 1em;
	border-radius: 1.5em 1.5em 0 0;
	color: var(--text-c1);
}

.ps-nav-pill li.active {
	background-color: var(--text-c1);
	color: var(--bg-c1);
}

.ps-nav-pill li.active:hover {
	background-color: var(--text-c1);
}

.ps-nav-pill li:hover {
	background-color: var(--bg-c3);
	cursor: pointer;
}