/*NOTE: WORK IN PROGRESS*/

.ps-sticky-header {
	position: sticky;
	top: 0;
}

.ps-hover-visible {
	visibility: hidden;
}

td:hover .ps-hover-visible {
	visibility: visible;
}

/* STYLING FOR TABLE*/
.ps-table {
	font-size: 1fr;
	border-collapse: collapse;
	width: 100%;
	color: var(--text-c1);
	background-color: var(--bg-c1);
}

.ps-table thead th {
	font-size: 1.2em;
	font-weight: bold;
	background-color: var(--bg-c4);
	border: 1px solid var(--border-3);
	padding: 0.75rem;
	text-align: left;
	caret-color: transparent;
}

.ps-table tbody td {
	border: 1px solid var(--border-3);
	padding: 0.75rem;
}

.ps-table tfoot td {
	border: 1px solid var(--border-3);
	padding: 0.75rem;
	font-weight: bold;
}

.ps-table tbody tr:nth-child(even) {
	background-color: var(--bg-c2);
}

.ps-table tfoot tr:nth-child(even) {
	background-color: var(--bg-c2);
}

.ps-table tbody tr:hover {
	background-color: var(--bg-c3);
}

.ps-table tfoot tr:hover {
	background-color: var(--bg-c3);
}

.ps-table tbody tr.select {
	background-color: var(--bg-c3);
}

.ps-table tfoot tr.select {
	background-color: var(--bg-c3);
}

.ps-table .fw-bold {
	font-weight: bold;
}

.ps-table.sort thead th:hover::after
{
	content: "\2191\2193";
	position: absolute;
}

td.ps-alert-nodata {
	color: var(--text-c1);
	background-color: var(--bg-c2);
}

/* STYLING FOR SINGLE ROW TABLE*/
table.ps-table-single-row {
	background-color: var(--bg-c1);
	width: 100%;
	border-bottom: 1px solid #c7c7c7;
}

table.ps-table-single-row thead th {
	font-size: 1.2em;
	font-weight: bold;
	border: none;
	padding: 0.75rem;
	text-align: center;
	color: var(--text-c1);
}

table.ps-table-single-row tbody td {
	border-bottom: 1px solid #dee2e6;
	padding: 0.75rem;
	text-align: center;
	font-size: 17px;
	color: var(--text-c1);
}

/* For tables where no hover is required (Such as tables with one row)*/
.ps-table-nohover tbody tr:hover {
	background-color: var(--bg-c1); 
}

.ps-table-borderless {
	width: 100%;
	border-collapse: collapse;
	text-align: justify;
}

.ps-table-borderless thead tr th {
	border-bottom: 2px solid var(--border-3);
	padding: 8px;
}

.ps-table-borderless tbody tr td {
	border-top: 1px solid var(--border-3);
	padding: 8px;
}

/* Centers only the table body cells */
.ps-table-centered-content tbody td {
	text-align: center;
}

/* Centers only the table header cells */
.ps-table-centered-header thead th {
	text-align: center;
}

/* Centers both thead and tbody cells */
.ps-table-centered thead th,
.ps-table-centered tbody td {
	text-align: center;
}

/* Keeps the header text on a single line (no wrapping) */
.ps-table-nowrap-header thead th {
	white-space: nowrap;
}

/* Allows the table columns to shrink to the minimum width required by their content (instead of expanding to fit the parent container) */
.ps-table-min-width {
	table-layout: auto;
	width: auto;
}
