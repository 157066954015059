.ps-alert-modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1050;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.6);
	display: flex;
	align-items: center;
	justify-content: center;
}

.ps-alert {
	font-size: 1.25em;
	min-width: 20vw;
	box-shadow: 0 5px 15px rgba(0,0,0,.5);
}

.ps-alert i.fa {
	color: white;
	font-size: 1.5em;
}

.ps-alert h2 {
	color: white;
	margin: 0 0 0 0;
}

.ps-alert p {
	margin: 15px;
}

.ps-alert-modal-header {
	text-align: center;
	padding: 1em;
	border-radius: 5px 5px 0 0;
}

.ps-alert-modal-body {
	text-align: center;
	color: #333333;
}

div.ps-alert-modal-footer {
	padding: 0.5em;
	margin-top: 2em;
}

div.ps-alert-modal-footer div {
	display: flex;
	justify-content: flex-end;
}

/* Specific alert types */
.ps-success-class div.ps-alert-modal-header {
	background-color: var(--fg-success);
}

.ps-warning-class div.ps-alert-modal-header {
	background-color: var(--fg-warning);
}

.ps-danger-class div.ps-alert-modal-header {
	background-color: var(--fg-danger);
}

.ps-error-class div.ps-alert-modal-header {
	background-color: var(--fg-danger);
}

.ps-info-class div.ps-alert-modal-header {
	background-color: var(--fg-primary);
}
