
/*Staging/Demo Banner*/
/*NOTE: this only works well with full screen/semi-full screen, not with smaller elements such as fieldsets*/
.ps-staging-banner {
	width: 100%;
	background-color: rgb(10, 132, 238);
	color: white;
	padding: 10px;
	overflow: hidden;
}

.ps-staging-banner span {
	display: inline-block;
	animation: marquee 20s linear infinite;
	font-size: 1.2em;
}

@keyframes marquee {
	0% {
		transform: translateX(100vw); /*Starts off-screen to the right*/
	}
	100% {
		transform: translateX(calc(0vw - 40em)); /*Ends off-screen to the left*/
	}
}
