.ps-accordion {
	width: 100%;
	margin: 2em auto;
	padding: 0;
	border: 1px solid var(--border-1);
	border-radius: 1.5em;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: var(--bg-c6);
}

.ps-accordion article{
	border-bottom: 1px solid var(--border-1);
}

.ps-accordion article:last-child {
	border-bottom: none;
}

.ps-accordion article > label{
	cursor: pointer;
	padding: 1.3em;
	font-size: 1.4em;
	font-weight: bold;
	color: var(--text-c1);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ps-accordion article > label::after {
	content: '\25BE';
	color: var(--text-c1);
}

.ps-accordion article > section {
	max-height: 0;
	overflow: hidden;
	padding: 0 1.7em;
}

.ps-accordion article label.selected::after {
	content: '\25B4';
}

.ps-accordion article .selected ~ section {
	max-height: none;
	padding: 1.5em 1.5em 1.5em;
}