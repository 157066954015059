/*NOTE: WORK IN PROGRESS*/
.ps-row {
	width: 100%;
}

.ps-row::after {
	clear: both;
}

.ps-row::after,
.ps-row::before {
	content: "";
	display: table;
}

.ps-nowrap {
	white-space: nowrap;
}

.ps-col-1,
.ps-col-2,
.ps-col-3,
.ps-col-4,
.ps-col-5,
.ps-col-6,
.ps-col-7,
.ps-col-8,
.ps-col-9,
.ps-col-10,
.ps-col-11,
.ps-col-12 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
	float: left;
}
.ps-col-1 { width: 8.33333333%; }
.ps-col-2 { width: 16.66666667%; }
.ps-col-3 { width: 25%; }
.ps-col-4 { width: 33.33333333%; }
.ps-col-5 { width: 41.66666667%; }
.ps-col-6 { width: 50%; }
.ps-col-7 { width: 58.33333333%; }
.ps-col-8 { width: 66.66666667%; }
.ps-col-9 { width: 75%; }
.ps-col-10 { width: 83.33333333%; }
.ps-col-11 { width: 91.66666667%; }
.ps-col-12 { width: 100%; }
